<template>
  <div class="block block--instruction">
    <div class="block__card card">
      <div class="block__content">
        <h3 class="block__title">{{ $j("instruction1") }}</h3>
        <div class="instruction">
          <ul class="instruction list">
            <li class="instruction__item" data-number="1" data-bs-toggle="modal" data-bs-target="#mod-1">
              <svg class="icon icon-youtube instruction__icon">
                <use xlink:href="images/icons/sprite.svg#youtube"></use>
              </svg><span class="instruction__text">{{ $j("instruction2") }}</span>
            </li>
            <div class="custom-modal custom-modal--video modal fade" id="mod-1" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true">
              <div class="custom-modal-dialog modal-dialog modal-xl modal-dialog-centered">
                <div class="custom-modal__content">         
                  <div class="custom-modal__header"> <b class="custom-modal__title">{{ $j("instruction2") }}</b>
                    <button class="custom-modal__close" data-bs-dismiss="modal">
                      <svg class="icon icon-close btn__icon custom-modal__icon--close">
                        <use xlink:href="images/icons/sprite.svg#close"></use>
                      </svg>
                    </button>
                  </div>
                  <div class="custom-modal__body">
                    <video class="custom-modal__video" controls="controls" loop="loop" width="100%" poster="" src="http://classifiers.egov.uz/video/1.mp4"></video>
                  </div>
                </div>
              </div>
            </div>
            <li class="instruction__item" data-number="2" data-bs-toggle="modal" data-bs-target="#mod-2">
              <svg class="icon icon-youtube instruction__icon">
                <use xlink:href="images/icons/sprite.svg#youtube"></use>
              </svg><span class="instruction__text">{{ $j("instruction3") }}</span>
            </li>
            <div class="custom-modal custom-modal--video modal fade" id="mod-2" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true">
              <div class="custom-modal-dialog modal-dialog modal-xl modal-dialog-centered">
                <div class="custom-modal__content">         
                  <div class="custom-modal__header"> <b class="custom-modal__title">{{ $j("instruction3") }}</b>
                    <button class="custom-modal__close" data-bs-dismiss="modal">
                      <svg class="icon icon-close btn__icon custom-modal__icon--close">
                        <use xlink:href="images/icons/sprite.svg#close"></use>
                      </svg>
                    </button>
                  </div>
                  <div class="custom-modal__body">
                    <video class="custom-modal__video" controls="controls" loop="loop" width="100%" poster="" src="http://classifiers.egov.uz/video/2.mp4"></video>
                  </div>
                </div>
              </div>
            </div>
            <li class="instruction__item" data-number="3" data-bs-toggle="modal" data-bs-target="#mod-3">
              <svg class="icon icon-youtube instruction__icon">
                <use xlink:href="images/icons/sprite.svg#youtube"></use>
              </svg><span class="instruction__text">{{ $j("instruction4") }}</span>
            </li>
            <div class="custom-modal custom-modal--video modal fade" id="mod-3" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true">
              <div class="custom-modal-dialog modal-dialog modal-xl modal-dialog-centered">
                <div class="custom-modal__content">         
                  <div class="custom-modal__header"> <b class="custom-modal__title">{{ $j("instruction4") }}</b>
                    <button class="custom-modal__close" data-bs-dismiss="modal">
                      <svg class="icon icon-close btn__icon custom-modal__icon--close">
                        <use xlink:href="images/icons/sprite.svg#close"></use>
                      </svg>
                    </button>
                  </div>
                  <div class="custom-modal__body">
                    <video class="custom-modal__video" controls="controls" loop="loop" width="100%" poster="" src="http://classifiers.egov.uz/video/3.mp4"></video>
                  </div>
                </div>
              </div>
            </div>
            <li class="instruction__item" data-number="4" data-bs-toggle="modal" data-bs-target="#mod-4">
              <svg class="icon icon-youtube instruction__icon">
                <use xlink:href="images/icons/sprite.svg#youtube"></use>
              </svg><span class="instruction__text">{{ $j("instruction5") }}</span>
            </li>
            <div class="custom-modal custom-modal--video modal fade" id="mod-4" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true">
              <div class="custom-modal-dialog modal-dialog modal-xl modal-dialog-centered">
                <div class="custom-modal__content">         
                  <div class="custom-modal__header"> <b class="custom-modal__title">{{ $j("instruction5") }}</b>
                    <button class="custom-modal__close" data-bs-dismiss="modal">
                      <svg class="icon icon-close btn__icon custom-modal__icon--close">
                        <use xlink:href="images/icons/sprite.svg#close"></use>
                      </svg>
                    </button>
                  </div>
                  <div class="custom-modal__body">
                    <video class="custom-modal__video" controls="controls" loop="loop" width="100%" poster="" src="http://classifiers.egov.uz/video/4.mp4"></video>
                  </div>
                </div>
              </div>
            </div>
            <li class="instruction__item" data-number="5" data-bs-toggle="modal" data-bs-target="#mod-5">
              <svg class="icon icon-youtube instruction__icon">
                <use xlink:href="images/icons/sprite.svg#youtube"></use>
              </svg><span class="instruction__text">{{ $j("instruction6") }}</span>
            </li>
            <div class="custom-modal custom-modal--video modal fade" id="mod-5" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true">
              <div class="custom-modal-dialog modal-dialog modal-xl modal-dialog-centered">
                <div class="custom-modal__content">         
                  <div class="custom-modal__header"> <b class="custom-modal__title">{{ $j("instruction6") }}</b>
                    <button class="custom-modal__close" data-bs-dismiss="modal">
                      <svg class="icon icon-close btn__icon custom-modal__icon--close">
                        <use xlink:href="images/icons/sprite.svg#close"></use>
                      </svg>
                    </button>
                  </div>
                  <div class="custom-modal__body">
                    <video class="custom-modal__video" controls="controls" loop="loop" width="100%" poster="" src="http://classifiers.egov.uz/video/5.mp4"></video>
                  </div>
                </div>
              </div>
            </div>
            <li class="instruction__item" data-number="6" data-bs-toggle="modal" data-bs-target="#mod-6">
              <svg class="icon icon-youtube instruction__icon">
                <use xlink:href="images/icons/sprite.svg#youtube"></use>
              </svg><span class="instruction__text">{{ $j("instruction7") }}</span>
            </li>
            <div class="custom-modal custom-modal--video modal fade" id="mod-6" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true">
              <div class="custom-modal-dialog modal-dialog modal-xl modal-dialog-centered">
                <div class="custom-modal__content">         
                  <div class="custom-modal__header"> <b class="custom-modal__title">{{ $j("instruction7") }}</b>
                    <button class="custom-modal__close" data-bs-dismiss="modal">
                      <svg class="icon icon-close btn__icon custom-modal__icon--close">
                        <use xlink:href="images/icons/sprite.svg#close"></use>
                      </svg>
                    </button>
                  </div>
                  <div class="custom-modal__body">
                    <video class="custom-modal__video" controls="controls" loop="loop" width="100%" poster="" src="http://classifiers.egov.uz/video/6.mp4"></video>
                  </div>
                </div>
              </div>
            </div>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
};
</script>